import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  Paths,
  Errors,
  UserOs
} from './Constants'
import {
  getConfigurationDefinition,
  getOsAndSupportedStatus
} from '../utils/Utils'

export class ConfigContextBusiness {
  static redirectsToNotFoundWhenTheSkuIsNotCompatibleWithUSB = ({
    pageDataCache,
    currentPath,
    sku,
    usbPaths,
    navigation,
    setError
  }) => {
    const configurationsPageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )
    if (!configurationsPageData || !currentPath || !sku) {
      return
    }
    const skuWithoutUsb = getConfigurationDefinition(
      configurationsPageData.definitions,
      ConfigurationDefinitionsKeys.skuWithoutUsbDefinition
    )

    if ((skuWithoutUsb || []).includes(sku) && usbPaths.includes(currentPath)) {
      if (setError) setError(Errors.sku_incompatible_with_usb)
      navigation.push(Paths.not_found)
    }
  }

  static compatibilityInformation = ({ pageDataCache, userAgent }) => {
    const configurationsPageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )
    if (!userAgent || !configurationsPageData) {
      return {}
    }
    const compatibilityDefinitionJSON = getConfigurationDefinition(
      configurationsPageData.definitions,
      ConfigurationDefinitionsKeys.compatibilityDefinitions
    )

    const {
      isMobile,
      os,
      isOsSupported,
      showInstallButton,
      isBrowserSupported
    } = getOsAndSupportedStatus(userAgent, compatibilityDefinitionJSON)

    return {
      isMobile,
      os,
      isOsSupported,
      showInstallButton,
      isBrowserSupported,
      compatibilityDefinition: compatibilityDefinitionJSON
    }
  }

  static navigationRules = ({
    customNavigation,
    sku,
    paas,
    os,
    osWithCompatibilityMessage,
    isBrowserSupported,
    isOsSupported
  }) => {
    if (paas) {
      if (customNavigation.has('printer-use')) {
        customNavigation.get('alignment').next = 'printer-use'
        customNavigation.get('printer-use').previous = 'alignment'
      } else if (
        customNavigation.has('alignment') &&
        customNavigation.has('hp-software')
      ) {
        customNavigation.get('alignment').next = 'hp-software'
        customNavigation.get('hp-software').previous = 'alignment'
      }
    } else if (
      customNavigation.has('alignment') &&
      customNavigation.has('hp-software')
    ) {
      customNavigation.get('alignment').next = 'hp-software'
      customNavigation.get('hp-software').previous = 'alignment'
    }

    if (
      !customNavigation.has('printer-use') &&
      !customNavigation.has('setup-checklist') &&
      customNavigation.has('alignment') &&
      customNavigation.has('unsupported-os') &&
      !osWithCompatibilityMessage.includes(os) &&
      (!isBrowserSupported || !isOsSupported)
    ) {
      customNavigation.get('alignment').next = 'unsupported-os'
      customNavigation.get('unsupported-os').previous = 'alignment'
    }

    if (os === UserOs.mac) {
      customNavigation.get('select-usb-on-display').next = 'start-airprint'
      customNavigation.get('start-airprint').previous = 'select-usb-on-display'
    }

    if (sku) {
      const printerSpecific = customNavigation.get(
        'printer-specific-landing-page'
      )
      printerSpecific.path = `/${sku}`
    }
  }
}
