import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { createBrowserHistory } from 'history'
import { FOOTER_ACTIONS, footerReducer } from './FooterReducers'
import { SIDEBAR_ACTIONS, sidebarReducer } from './SidebarReducers'
import {
  getConfigurationDefinition,
  getStoreData as getLocalStoreData,
  printerFamilyByDerivativeName,
  resetLocalStorage,
  setStoreData as setLocalStoreData
} from '../utils/Utils'
import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  LocalStoreKeys,
  Paths,
  PrinterFamily,
  UserOs
} from './Constants'
import { useContentStack } from '@jarvis/olex-content-management'
import { useHpPlusClient } from '../hooks/useHpPlusClient'
import { ConfigContextBusiness } from './ConfigContextBusiness'
import { FeatureFlagsContext } from './FeatureFlagsContext'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EMPTY_FUNCTION = () => {}
const INIT = (i) => {
  return { ...i }
}
export const ConfigContext = React.createContext({
  contentStackCredential: null,
  publishCdmEvent: EMPTY_FUNCTION,
  setSelectedTab: EMPTY_FUNCTION,
  setPreviousSelectedTab: EMPTY_FUNCTION,
  setSelectedTabItem: EMPTY_FUNCTION,
  v1: {
    header: {
      setSelectedTab: EMPTY_FUNCTION,
      setPreviousSelectedTab: EMPTY_FUNCTION,
      selectedTab: 0,
      previousSelectedTab: 0,
      selectedTabItem: {}
    },
    sidebar: {
      setSidebarVisibility: EMPTY_FUNCTION,
      setCustomSidebar: EMPTY_FUNCTION
    },
    footer: {
      setFooterNavVisibility: EMPTY_FUNCTION,
      setCustomNavigation: EMPTY_FUNCTION,
      updateCurrentRoute: EMPTY_FUNCTION,
      updatePreviousRoute: EMPTY_FUNCTION,
      nextRoute: EMPTY_FUNCTION,
      setNextDisabled: EMPTY_FUNCTION,
      setNextVisible: EMPTY_FUNCTION,
      setBackDisabled: EMPTY_FUNCTION,
      setBackVisible: EMPTY_FUNCTION
    }
  },
  stack: null,
  sku: null,
  setSku: EMPTY_FUNCTION,
  printer: null,
  setPrinter: EMPTY_FUNCTION,
  paas: null,
  setPaas: EMPTY_FUNCTION,
  pageDataCache: new Map(),
  os: null,
  isOsSupported: null,
  showInstallButton: null,
  isBrowserSupported: null,
  isMobile: null,
  localization: null,
  currentPath: null,
  isHpPlus: null,
  isFlowers: null,
  error: null,
  setError: EMPTY_FUNCTION,
  isInstallRedirectURL: null,
  navigationDefinition: null,
  flags: null
})

const DEFAULT_COUNTRY = 'us'
const DEFAULT_LANGUAGE = 'en'

const ConfigProvider = (props) => {
  const {
    localization = {
      country: DEFAULT_COUNTRY,
      language: DEFAULT_LANGUAGE,
      enabled: false
    },
    analytics,
    getStoreData,
    setStoreData,
    stack,
    contentStackCredential,
    userAgent
  } = props

  const getStoreDataFunction = getStoreData || getLocalStoreData
  const setStoreDataFunction = setStoreData || setLocalStoreData

  const { flags } = useContext(FeatureFlagsContext)
  const country = localization.country.toUpperCase()
  const language = localization.language
  const enabled = localization.enabled
  const history = createBrowserHistory({
    basename: enabled ? `/${country.toLowerCase()}/${language}` : `/`
  })
  const navigation = useMemo(
    () => props.navigation || history,
    [props.navigation, history]
  )
  const [lastKnownRoute, setLastKnownRoute] = useState()
  const [paas, setPaas] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [previousSelectedTab, setPreviousSelectedTab] = useState(0)
  const [sidebarState, sidebarDispatch] = useReducer(
    sidebarReducer,
    { sidebarVisible: false, customSidebar: {} },
    INIT
  )
  const [sku, setSku] = useState(null)
  const [printer, setPrinter] = useState(null)
  const [selectedTabItem, _setSelectedTabItem] = useState({ 0: 0, 1: 0, 2: 0 })
  const [currentPath, setCurrentPath] = useState(navigation.location.pathname)
  const { isHpPlus } = useHpPlusClient({ printer })
  const isFlowers = useMemo(
    () =>
      [PrinterFamily.FLOWERS_CHERRY, PrinterFamily.FLOWERS_LOTUS].includes(
        printerFamilyByDerivativeName(printer?.derivativeName)
      ),
    [printer?.derivativeName]
  )
  const [error, setError] = useState(null)

  const osWithCompatibilityMessage = [UserOs.mac]

  const setSelectedTabItem = useCallback(
    (tab, subtab) => {
      _setSelectedTabItem((prevTabItem) => {
        return {
          ...prevTabItem,
          [tab]: subtab
        }
      })
    },
    [selectedTab]
  )

  const isInstallRedirectURL = useMemo(
    () =>
      [Paths.install, Paths.install_unsupported_os].includes(
        navigation?.location?.pathname
      ),
    [navigation?.location?.pathname]
  )

  useEffect(() => {
    if (!sku || isInstallRedirectURL) {
      return
    }
    const storedUserAgent = getLocalStoreData(LocalStoreKeys.lastUserAgent)
    if (!storedUserAgent) {
      setLocalStoreData(LocalStoreKeys.lastUserAgent, userAgent)
    } else if (userAgent !== storedUserAgent) {
      navigation.push(`/${sku}`)
      setLocalStoreData(LocalStoreKeys.lastUserAgent, userAgent)
    }
  }, [sku, userAgent])

  const { pageData: configurationsPageData } = useContentStack({
    content_type: ContentStackTypes.configuration_definitions
  })

  const { pageData: externalLinksPageData } = useContentStack({
    content_type: ContentStackTypes.external_links
  })

  const { pageData: tdeLayoutPageData } = useContentStack({
    content_type: ContentStackTypes.tde_layout
  })

  const pageDataCache = useMemo(() => {
    return new Map([
      [
        ContentStackTypes.external_links.content_type_uid,
        externalLinksPageData.data
      ],
      [ContentStackTypes.tde_layout.content_type_uid, tdeLayoutPageData.data],
      [
        ContentStackTypes.configuration_definitions.content_type_uid,
        configurationsPageData.data
      ]
    ])
  }, [tdeLayoutPageData, externalLinksPageData, configurationsPageData])

  const navigationDefinition = useMemo(() => {
    const pageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )

    if (pageData) {
      return getConfigurationDefinition(
        pageData.definitions,
        ConfigurationDefinitionsKeys.navigation
      )
    }
    return null
  }, [pageDataCache])

  const {
    os,
    isBrowserSupported,
    isOsSupported,
    showInstallButton,
    isMobile,
    compatibilityDefinition
  } = useMemo(
    () =>
      ConfigContextBusiness.compatibilityInformation({
        pageDataCache,
        userAgent
      }),
    [userAgent, pageDataCache]
  )

  const [footerState, footerDispatch] = useReducer(
    footerReducer,
    {
      footerNavigationVisible: false,
      customNavigation: new Map(),
      currentRoute: {},
      nextDisabled: false,
      nextVisible: true,
      nextButtonLabel: null,
      backDisabled: false,
      backVisible: true,
      backButtonLabel: null
    },
    INIT
  )

  const refreshCurrentPath = useCallback(() => {
    setCurrentPath(navigation.location.pathname)
  }, [navigation.location.pathname])

  useEffect(() => {
    window.addEventListener('popstate', refreshCurrentPath)

    return () => {
      window.removeEventListener('popstate', refreshCurrentPath)
    }
  }, [refreshCurrentPath])

  useEffect(() => {
    if (
      footerState.customNavigation &&
      currentPath &&
      currentPath !== footerState.currentRoute.path
    ) {
      const allKeys = Array.from(footerState.customNavigation.keys())
      const currentKey = allKeys.find(
        (key) => footerState.customNavigation.get(key).path === currentPath
      )
      if (currentKey) {
        setLastKnownRoute(currentKey)
        updateCurrentRoute({ key: currentKey })
      }
    }
  }, [footerState.customNavigation, currentPath])

  //watch for sku changes and save to local storage
  useEffect(() => {
    if (sku) setStoreDataFunction(LocalStoreKeys.printer_sku, sku)
  }, [sku])

  //watch for sku changes and save to local storage
  useEffect(() => {
    if (localization) {
      const existingLocale = getStoreDataFunction(LocalStoreKeys.localization)
      const savedSku = getStoreDataFunction(LocalStoreKeys.printer_sku)
      if (
        existingLocale &&
        existingLocale !== localization?.locale.toLowerCase()
      ) {
        if (navigation.location.pathname === `/${savedSku}`) {
          resetLocalStorage(localization.locale)
          navigation.push(`${savedSku}${navigation.location.search}`)
        }
      } else {
        setStoreDataFunction(
          LocalStoreKeys.localization,
          localization?.locale.toLowerCase()
        )
      }
    }
  }, [localization])

  //watch for printer changes and save to local storage
  useEffect(() => {
    if (printer) {
      setStoreDataFunction(LocalStoreKeys.printer, printer)
    }
  }, [printer])

  /************************
   * SIDEBAR
   ************************/
  const setSidebarVisibility = (visible) => {
    sidebarDispatch({ type: SIDEBAR_ACTIONS.VISIBILITY, visible })
  }

  const setCustomSidebar = (sidebar) => {
    sidebarDispatch({ type: SIDEBAR_ACTIONS.CUSTOM_SIDEBAR, sidebar })
  }

  /************************
   * FOOTER
   ************************/
  useEffect(() => {
    if (
      lastKnownRoute &&
      footerState?.currentRoute &&
      footerState.currentRoute.key !== lastKnownRoute
    ) {
      setLastKnownRoute(footerState.currentRoute.key)
      navigation.push(footerState.currentRoute.path)
    } else if (!lastKnownRoute && footerState.currentRoute) {
      setLastKnownRoute(footerState.currentRoute.key)
    }
  }, [footerState, navigation, lastKnownRoute])

  const setFooterNavVisibility = (visible) => {
    footerDispatch({ type: FOOTER_ACTIONS.VISIBILITY, visible })
  }

  const setCustomNavigation = (navigation) => {
    footerDispatch({
      type: FOOTER_ACTIONS.NAVIGATION,
      navigation
    })
  }

  const nextRoute = (options) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT, options })
  }

  const updateCurrentRoute = (route) => {
    footerDispatch({ type: FOOTER_ACTIONS.UPDATE, route })
  }

  const updatePreviousRoute = (options) => {
    footerDispatch({ type: FOOTER_ACTIONS.PREVIOUS, options })
  }

  const setNextVisible = (visible) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT_VISIBLE, visible })
  }

  const setNextDisabled = (disabled) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT_DISABLED, disabled })
  }

  const setNextButtonLabel = (label) => {
    footerDispatch({ type: FOOTER_ACTIONS.NEXT_LABEL, label })
  }

  const setBackVisible = (visible) => {
    footerDispatch({ type: FOOTER_ACTIONS.BACK_VISIBLE, visible })
  }

  const setBackDisabled = (disabled) => {
    footerDispatch({ type: FOOTER_ACTIONS.BACK_DISABLED, disabled })
  }

  const setBackButtonLabel = (label) => {
    footerDispatch({ type: FOOTER_ACTIONS.BACK_LABEL, label })
  }

  let publishCdmEvent = EMPTY_FUNCTION
  if (analytics?.publishCdmEvents) {
    publishCdmEvent = (events, metadata) => {
      events = Array.isArray(events) ? events : [events]
      metadata
        ? analytics.publishCdmEvents(events, metadata)
        : analytics.publishCdmEvents(events)
    }
  }

  const currentCustomNavigation = useMemo(() => {
    if (navigationDefinition) {
      const customNavigation = new Map(
        Object.entries(navigationDefinition).sort(([, value1]) => {
          return value1.previous ? 1 : -1
        })
      )

      ConfigContextBusiness.navigationRules({
        customNavigation,
        sku,
        paas,
        os,
        osWithCompatibilityMessage,
        isBrowserSupported,
        isOsSupported
      })
      return customNavigation
    }
  }, [navigationDefinition, paas, sku])

  const header = {
    selectedTab,
    setSelectedTab,
    previousSelectedTab,
    setPreviousSelectedTab,
    selectedTabItem,
    setSelectedTabItem
  }
  const sidebar = {
    setSidebarVisibility,
    setCustomSidebar
  }
  const footer = {
    setFooterNavVisibility,
    currentCustomNavigation,
    setCustomNavigation,
    updateCurrentRoute,
    updatePreviousRoute,
    nextRoute,
    setNextVisible,
    setNextDisabled,
    setNextButtonLabel,
    setBackVisible,
    setBackDisabled,
    setBackButtonLabel
  }

  useEffect(() => {
    if (currentCustomNavigation) {
      footer.setCustomNavigation(currentCustomNavigation)
    }
  }, [currentCustomNavigation])

  const usbPaths = [
    Paths.select_usb_on_display,
    Paths.connect_usb,
    Paths.driver_download,
    Paths.driver_after
  ]

  useEffect(() => {
    ConfigContextBusiness.redirectsToNotFoundWhenTheSkuIsNotCompatibleWithUSB({
      currentPath,
      navigation,
      pageDataCache,
      sku,
      usbPaths,
      setError
    })
  }, [pageDataCache, navigation, currentPath, sku])

  const configState = useMemo(() => {
    return {
      contentStackCredential,
      navigation,
      states: {
        header: {
          selectedTab,
          previousSelectedTab,
          selectedTabItem,
          setSelectedTabItem
        },
        sidebar: sidebarState,
        footer: footerState
      },
      header,
      sidebar,
      footer,
      publishCdmEvent,
      setSelectedTab,
      setPreviousSelectedTab,
      stack,
      sku,
      setSku,
      printer,
      setPrinter,
      paas,
      setPaas,
      setStoreData: setStoreDataFunction,
      getStoreData: getStoreDataFunction,
      pageDataCache,
      os,
      isBrowserSupported,
      isOsSupported,
      showInstallButton,
      isMobile,
      localization,
      currentPath,
      isHpPlus,
      compatibilityDefinition,
      isFlowers,
      error,
      setError,
      isInstallRedirectURL,
      navigationDefinition,
      flags
    }
  }, [
    contentStackCredential,
    navigation,
    selectedTab,
    previousSelectedTab,
    selectedTabItem,
    sidebarState,
    footerState,
    header,
    sidebar,
    footer,
    publishCdmEvent,
    setSelectedTab,
    setPreviousSelectedTab,
    setSelectedTabItem,
    sku,
    setSku,
    printer,
    setPrinter,
    paas,
    setPaas,
    setStoreDataFunction,
    getStoreDataFunction,
    pageDataCache,
    os,
    isBrowserSupported,
    isOsSupported,
    showInstallButton,
    isMobile,
    currentPath,
    localization,
    isHpPlus,
    compatibilityDefinition,
    isFlowers,
    error,
    setError,
    isInstallRedirectURL,
    navigationDefinition,
    flags
  ])

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
